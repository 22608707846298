/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/dylayed"
  }, "Daniel Lee (@dylayed) / Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/taeold"
  }, "taeold (Daniel Lee)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://typefully.com/dylayed"
  }, "Daniel Lee (@dylayed) | Typefully")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://youtu.be/61WC7urgvQ4"
  }, "How to monitor errors and performance in Cloud Functions with Open Telemetry - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://typefully.com/dylayed/PzIV7Qs"
  }, "큰 서버 작은 서버: 서비스 스케일링의 효율성")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://link.coupang.com/a/GAVxN"
  }, "구글 엔지니어는 이렇게 일한다 - 쿠팡")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://info.honeycomb.io/observability-engineering-oreilly-book-2022"
  }, "Observability Engineering - O'Reilly Book 2022 Download")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.honeycomb.io/"
  }, "Observability for Distributed Services | Honeycomb")), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
